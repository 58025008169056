"use client";

import { useEffect, useRef, useState } from "react";

interface ConfettiProps {
  count?: number;
  colors?: string[];
}

interface ConfettiPiece {
  id: number;
  x: number;
  y: number;
  rotation: number;
  rotationSpeed: number;
  scale: number;
  color: string;
  speed: number;
  opacity: number;
  bendAmount: number;
  bendDirection: "left" | "right" | "up" | "down";
}

export function Confetti({
  count = 50,
  colors = ["#FDB347", "#B4D87C", "#A7A1D8", "#F45B69", "#FFD700"],
}: ConfettiProps) {
  const [confetti, setConfetti] = useState<ConfettiPiece[]>([]);
  const requestRef = useRef<number>();

  const generateConfetti = () => {
    return Array.from({ length: count }, (_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: Math.random() * -100,
      rotation: Math.random() * 360,
      rotationSpeed: (Math.random() * 2 - 1) * 0.5, // Reduced rotation speed
      scale: Math.random() * 0.3 + 0.7,
      color: colors[Math.floor(Math.random() * colors.length)],
      speed: (Math.random() * 0.5 + 0.5) * 0.5, // Reduced falling speed
      opacity: 1,
      bendAmount: Math.random() * 30 + 10, // Random bend amount between 10 and 40 degrees
      bendDirection: ["left", "right", "up", "down"][
        Math.floor(Math.random() * 4)
      ] as "left" | "right" | "up" | "down",
    }));
  };

  const animateConfetti = () => {
    setConfetti((prevConfetti) =>
      prevConfetti.map((piece) => {
        if (piece.y > 100) {
          return {
            ...piece,
            y: Math.random() * -100,
            x: Math.random() * 100,
            opacity: 0,
            bendAmount: Math.random() * 30 + 10,
            bendDirection: ["left", "right", "up", "down"][
              Math.floor(Math.random() * 4)
            ] as "left" | "right" | "up" | "down",
          };
        }
        return {
          ...piece,
          y: piece.y + piece.speed,
          rotation: (piece.rotation + piece.rotationSpeed) % 360,
          opacity: Math.min(piece.opacity + 0.02, 1), // Slower fade-in
        };
      })
    );
    requestRef.current = requestAnimationFrame(animateConfetti);
  };

  useEffect(() => {
    setConfetti(generateConfetti());
    requestRef.current = requestAnimationFrame(animateConfetti);

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, []);

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none" style={{zIndex: 10}}>
      {confetti.map((piece) => (
        <div
          key={piece.id}
          className="absolute w-8 h-10"
          style={{
            left: `${piece.x}%`,
            top: `${piece.y}%`,
            transform: `rotate(${piece.rotation}deg) scale(${piece.scale})`,
            opacity: piece.opacity,
            transition: "opacity 1s ease-in",
          }}
        >
          <div
            className={`w-full h-full paper-bend-${piece.bendDirection}`}
            style={{
              backgroundColor: piece.color,
              boxShadow:
                "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              "--bend-amount": `${piece.bendAmount}deg`,
            } as React.CSSProperties}
          />
        </div>
      ))}
    </div>
  );
}
