"use client";

import React, { useEffect, useRef } from "react";
import Image from "next/image";
interface SliderRowProps {
	reverse?: boolean;
	copy: { title: string; subtitle: string; description?: string };
	image?: string;
}

const Row = ({ reverse, copy }: SliderRowProps) => {
	return (
		<div
			className={`text-pretty md:text-balance text-left border-grey2 border-y bg-grey1 md:py-12 md:px-12 px-8 py-12`}
		>
			<div
				className={`max-w-screen-xl mx-auto flex flex-col justify-between ${
					reverse ? "md:flex-row-reverse" : "md:flex-row"
				}`}
			>
				<h2
					className="md:text-6xl text-5xl font-extrabold font-forma-djr-display"
					dangerouslySetInnerHTML={{ __html: copy.title }}
				/>
				<p
					className="md:w-1/3"
					dangerouslySetInnerHTML={{ __html: copy.subtitle }}
				/>
			</div>
		</div>
	);
};

const Content = ({ reverse, copy, image }: SliderRowProps) => {
	return (
		<div
			className={`min-h-[900px] relative px-8 py-12 md:py-14 md:px-6 box-border text-grey5 flex flex-col-reverse gap-12 md:gap-0 justify-between ${
				reverse ? "md:flex-row-reverse" : "md:flex-row"
			}`}
			style={{
				backgroundImage: "url(/assets/tenyears/img/squiggly_line.png)",
				backgroundSize: "400px",
				backgroundRepeat: "repeat",
			}}
		>
			<div className="max-w-screen-xl mx-auto">
				<div
					className={`w-full md:w-2/5 flex flex-col gap-12 justify-center h-full md:pb-20 ${
						reverse ? "float-right" : "float-left"
					}`}
				>
					<p dangerouslySetInnerHTML={{ __html: copy.description }} />
					{copy.checklist && (
						<ul className="flex flex-col gap-10 list-none m-0 p-0">
							{copy.checklist.map((item, index) => (
								<li key={index} className="">
									<div className="flex items-start gap-2">
										<Image
											src="/assets/general/img/check_circle.png"
											alt="Check"
											width={30}
											height={30}
										/>
										<p
											dangerouslySetInnerHTML={{
												__html: item,
											}}
										/>
									</div>
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
			{image && (
				<Image
					className={`h-[400px] md:h-4/5 md:absolute object-cover object-center rounded-lg md:rounded-none ${
						reverse
							? "md:rounded-r-lg left-0"
							: "md:rounded-l-lg right-0"
					}`}
					src={image}
					alt="Slider Image"
					width={500}
					height={750}
				/>
			)}
		</div>
	);
};

const SliderRow = ({ data }: SliderRowProps) => {
	const { reverse = false, copy, image } = data;
	const containerRef = useRef(null);
	const contentRef = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current) {
				const containerOnTop =
					containerRef.current.getBoundingClientRect().top <= 60;
				if (containerOnTop && contentRef.current) {
					contentRef.current.style.maxHeight = "2000px";
				} else {
					contentRef.current.style.maxHeight = "0px";
				}
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [containerRef, contentRef]);

	return (
		<div className="overflow-hidden" ref={containerRef}>
			<Row reverse={reverse} copy={copy} />
			<div
				className="max-h-0 transition-all duration-[2000ms] ease-in-out"
				ref={contentRef}
			>
				<Content copy={copy} image={image} reverse={reverse} />
			</div>
		</div>
	);
};

export default SliderRow;
