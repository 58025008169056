"use client"

import React, { useState } from 'react'
import { ChevronDownIcon } from 'lucide-react'

interface AccordionItemProps {
  question: string
  answer: string
}

const AccordionItem: React.FC<AccordionItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="border-b border-[#E5E5E5] last:border-b-0 font-plus-jakarta-sans">
      <button
        className="flex justify-between items-center w-full py-6 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-[#2D2D2D] text-xl font-bold">{question}</span>
        <ChevronDownIcon
          className={`w-6 h-6 text-[#2D2D2D] transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      {isOpen && (
        <div className="pb-6">
          <p className="text-[#2D2D2D] text-lg">{answer}</p>
        </div>
      )}
    </div>
  )
}

const faqData = [
  {
    question: "How do I nominate someone?",
    answer: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a tellus et mauris mattis hendrerit. Nulla varius id felis vel posuere. Nulla tristique libero et pretium lacinia. Praesent velit elit, suscipit id tortor non, suscipit finibus lacus. Aenean nec neque vitae risus dignissim egestas."
  },
  {
    question: "How do I vote for someone?",
    answer: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a tellus et mauris mattis hendrerit. Nulla varius id felis vel posuere. Nulla tristique libero et pretium lacinia. Praesent velit elit, suscipit id tortor non, suscipit finibus lacus. Aenean nec neque vitae risus dignissim egestas."
  },
  {
    question: "When is the voting deadline?",
    answer: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a tellus et mauris mattis hendrerit. Nulla varius id felis vel posuere. Nulla tristique libero et pretium lacinia. Praesent velit elit, suscipit id tortor non, suscipit finibus lacus. Aenean nec neque vitae risus dignissim egestas."
  },
  {
    question: "How will the winners be chosen?",
    answer: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a tellus et mauris mattis hendrerit. Nulla varius id felis vel posuere. Nulla tristique libero et pretium lacinia. Praesent velit elit, suscipit id tortor non, suscipit finibus lacus. Aenean nec neque vitae risus dignissim egestas."
  },
  {
    question: "What happens if I or my nominee wins?",
    answer: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a tellus et mauris mattis hendrerit. Nulla varius id felis vel posuere. Nulla tristique libero et pretium lacinia. Praesent velit elit, suscipit id tortor non, suscipit finibus lacus. Aenean nec neque vitae risus dignissim egestas."
  }
]

export function FAQ() {
  return (
    <div className="w-full">
      {faqData.map((item, index) => (
        <AccordionItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  )
}

