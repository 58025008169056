'use client'

import React, { useState, useEffect } from "react";
import Image from 'next/image'
import { Menu, X } from 'lucide-react'
import { Link } from "@/shared/components/Link";

interface Page {
    name: string;
    path: string;
}

const pages: Page[] = [
    { name: "Home", path: "/home" },
    { name: "Milkman Beliefs", path: "/values" },
    { name: "Best Milkman Awards", path: "/awards" },
    { name: "Podcast", path: "/podcast" },
];

const NavigationComponent: React.FC = ({ options }: any) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const currentRoute = `/${options.args.route.join("/")}`;

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    useEffect(() => {
      if (isMenuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }

      return () => {
        document.body.style.overflow = 'unset';
      };
    }, [isMenuOpen]);

    return (
        <header className="relative">
            <div className="absolute top-4 left-4 z-20">
                <Image src="/assets/tenyears/img/picnic-10-logo.png" alt='Logo' width={50} height={50} />
            </div>
            
            {/* Hamburger menu for mobile */}
            <button 
                className="lg:hidden absolute top-4 right-4 z-[61]" 
                onClick={toggleMenu}
                aria-label="Toggle menu"
            >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>

            {/* Desktop navigation */}
            <nav className="hidden lg:block absolute top-4 left-1/2 transform -translate-x-1/2 z-10">
                <div className="bg-white rounded-full shadow px-4 py-2">
                    <ul className="flex items-center justify-center space-x-1 sm:space-x-2 md:space-x-4">
                        {pages.map((page) => (
                            <li key={page.name} className="list-none whitespace-nowrap">
                                <Link href={page.path}>
                                    <span
                                        className={`px-2 sm:px-3 md:px-4 py-1 rounded-full text-xs sm:text-sm md:text-base transition-colors duration-200 ${
                                            currentRoute === page.path ? "bg-green5" : "hover:bg-gray-100"
                                        }`}
                                    >
                                        {page.name}
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>

            {/* Mobile navigation */}
            {isMenuOpen && (
                <nav className="lg:hidden fixed inset-0 bg-white z-[60] overflow-y-auto">
                    <ul className="flex flex-col items-center justify-center min-h-full py-20 space-y-4">
                        {pages.map((page) => (
                            <li key={page.name} className="list-none">
                                <Link href={page.path} onClick={toggleMenu}>
                                    <span
                                        className={`px-4 py-2 rounded-full text-lg ${
                                            currentRoute === page.path ? "bg-green5 text-white" : ""
                                        }`}
                                    >
                                        {page.name}
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            )}
        </header>
    );
};

export default NavigationComponent;

