/** @type {import('tailwindcss').Config} */
const plugin = require("tailwindcss/plugin");
const defaultTheme = require("tailwindcss/defaultTheme");
module.exports = {
	content: [
		"./sites/**/*.{js,ts,jsx,tsx}",
		"./shared/components/**/*.{js,ts,jsx,tsx}",
		"./shared/components/**/*.styles.{js,ts,jsx,tsx}",
		"./shared/styles/**/*.{js,ts,jsx,tsx}",
	],
	safelist: [
		{ pattern: /bg-./ },
		{ pattern: /text-./ },
		{ pattern: /border-./ },
		{ pattern: /!?h-./, variants: ["md", "lg"] },
		{ pattern: /!?w-./, variants: ["md", "lg"] },
		{ pattern: /^!?p(\w?)-/, variants: ["md", "lg"] },
		{ pattern: /rounded-./ },
		"object-cover",
		"max-h-[110vh]",
		"md:max-h-none",
		"md:pb-20"
	],
	theme: {
		colors: {
			transparent: "transparent",
			white: "#ffffff",
			red1: "#E1171E",
			red2: "#B40117",
			green1: "#308807",
			green2: "#88A91E",
			green4: "#6B7A3B",
			green5: "#f3F6E9",
			green6: "#009F00",
			green7: "#295813",
			blue1: "#26A4FF",
			blue2: "#6D9BD2",
			blue3: "#2E3140",
			blue4: "#87BBEB",
			orange1: "#FF6B00",
			orange2: "#FDF9F3",
			yellow2: "#FBD92B",
			grey0: "#FCFAF9",
			grey1: "#F8F5F2",
			grey2: "#C9C6C3",
			grey3: "#84817D",
			grey4: "#5B534E",
			grey5: "#333333",
			black: "#000000",
			tan: "#FDF9F3"
		},
		dropShadow: {
			DEFAULT: "0px 2px 8px rgba(0, 0, 0, 0.12)",
			straight: "0px 0px 4px rgba(0, 0, 0, 0.12)",
			faded: "0px 4px 40px rgba(0, 0, 0, 0.1)",
			card: "0px 2px 8px 0px rgba(0, 0, 0, 0.12)",
			none: "0px 0px 0px 0px rgba(0, 0, 0, 0)",
		},
		borderRadius: {
			none: "0",
			xs: "2px",
			sm: "4px",
			md: "6px",
			DEFAULT: "8px",
			lg: "12px",
			"2lg": "14px",
			"3lg": "16px",
			xl: "20px",
			"2xl": "22px",
			"3xl": "24px",
			full: "9999px",
		},
		boxShadow: {
			DEFAULT: "0px 2px 8px 0px rgba(0, 0, 0, 0.12)",
		},
		extend: {
			transitionProperty: {
				'height': 'height',
				'max-height': 'max-height',
			},
			fontFamily: {
				sans: ["Roboto", "sans-serif", ...defaultTheme.fontFamily.sans],
				inter: ["Inter", "sans-serif"],
				"source-sans-pro": [
					"Source Sans Pro",
					"Roboto",
					"sans-serif",
					...defaultTheme.fontFamily.sans,
				],
				"forma-djr-micro": [
					"Forma DJR Micro",
					"Roboto",
					"sans-serif",
					...defaultTheme.fontFamily.sans,
				],
			  "forma-djr-display": [
					"Forma DJR Display",
					"Forma DJR Micro",
					"Roboto",
					"sans-serif",
					...defaultTheme.fontFamily.sans,
				],
				"plus-jakarta-sans": [
					"Plus Jakarta Sans",
					"Roboto",
					"sans-serif",
					...defaultTheme.fontFamily.sans,
				],
			},
			maxWidth: {
				big: "70rem" /* 1120px */,
			},
			height: {
				104: "26rem" /* 416px */,
				112: "28rem" /* 448px */,
				120: "30rem" /* 480px */,
				128: "32rem" /* 512px */,
				136: "34rem" /* 544px */,
				144: "36rem" /* 576px */,
				152: "38rem" /* 608px */,
				160: "40rem" /* 640px */,
			},
			padding: {
				26: "6.5rem",
				27: "6.75rem",
			},
			textShadow: {
				sm: "0 1px 2px var(--tw-shadow-color)",
				DEFAULT: "0 2px 4px var(--tw-shadow-color)",
				lg: "0 8px 16px var(--tw-shadow-color)",
			},

			keyframes: {
				"paper-flex-left": {
					"0%, 100%": { transform: "rotateY(0deg)" },
					"50%": { transform: "rotateY(-15deg)" },
				},
				"paper-flex-right": {
					"0%, 100%": { transform: "rotateY(0deg)" },
					"50%": { transform: "rotateY(15deg)" },
				},
			},
			animation: {
				"paper-flex-left": "paper-flex-left 2s ease-in-out infinite",
				"paper-flex-right": "paper-flex-right 2s ease-in-out infinite",
			},
		},
	},
	plugins: [
		require("@tailwindcss/typography"),
		plugin(function groupPeer({ addVariant }) {
			// Very cool solution for children accessing peers I found at https://github.com/tailwindlabs/tailwindcss/pull/4556
			let pseudoVariants = ["checked"].map((variant) =>
				Array.isArray(variant) ? variant : [variant, `&:${variant}`]
			);
			for (let [variantName, state] of pseudoVariants) {
				addVariant(`group-peer-${variantName}`, (ctx) => {
					let result =
						typeof state === "function" ? state(ctx) : state;
					return result.replace(
						/&(\S+)/,
						":merge(.peer)$1 ~ .group &"
					);
				});
			}
		}),
		plugin(function ({ matchUtilities, theme }) {
			matchUtilities(
				{
					"text-shadow": (value) => ({
						textShadow: value,
					}),
				},
				{ values: theme("textShadow") }
			);
		}),
		plugin(function ({ addUtilities, theme }) {
			const shadows = theme("dropShadow");

			const webkitShadows = Object.keys(shadows).reduce((acc, key) => {
				const className =
					key === "DEFAULT" ? ".drop-shadow" : `.drop-shadow-${key}`;
				const shadowValue = shadows[key];
				acc[className] = {
					"box-shadow": shadowValue,
					"-webkit-box-shadow": shadowValue,
				};
				return acc;
			}, {});

			addUtilities(webkitShadows);
		}),
	],
};
